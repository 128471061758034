import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useRef } from 'react'
import classes from './Button.module.scss';

export enum Variant {
    outlined = 'outlined',
    outlinedPrimary = 'outlinedPrimary',
    outlinedWhite = 'outlinedWhite',
    filled = 'filled',
    filledPrimary = 'filledPrimary',
    filledWhite = 'filledWhite',
    outlinedSecondary = 'outlinedSecondary',
    text = 'text',
}

type ButtonProps = {
    id: string;
    children?: string | React.ReactNode;
    variant?: Variant | string;
    fullWidth?: boolean;
    startIcon?: any;
    endIcon?: any;
    pressed?: boolean;
    style?: object;
    disabled?: boolean;
    onClick?: any;
    parentRef?: any;
    type?: string;
    loading?: boolean;
    className?: any;
}

const Button: React.FC<ButtonProps> = ({
    variant = 'filled',
    children,
    id,
    startIcon,
    endIcon,
    onClick,
    pressed,
    disabled,
    fullWidth,
    parentRef,
    type,
    loading = false,
    className,
    ...rest
}) => {
    const buttonRef = parentRef || useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        const computedStyle = window.getComputedStyle(buttonRef?.current!);
        const fontSize = computedStyle?.fontSize;

        if (startIcon || endIcon) {
            const imgElement = buttonRef?.current!.querySelector('img');
            if (imgElement) {
                imgElement.style.width = fontSize;
                imgElement.style.height = fontSize;
            }
        }
    }, []);

    return (
        <button
            ref={buttonRef}
            id={id}
            disabled={disabled || loading}
            className={`
                ${classes[`rb-button`]}
                ${classes[`rb-button-${variant}`]}
                ${pressed ? classes[`rb-button-${variant}-pressed`] : ''}
                ${disabled || loading ? classes[`rb-button-disabled`] : ''}
                ${fullWidth ? classes[`rb-button-fullWidth`] : ''}
                ${className ? className : null}
           `}
            onClick={onClick}
            type={type as any}
            {...rest}
        >
            {startIcon}
            {children}
            {endIcon}
            {loading && (
                <div className={classes['rb-button-loading']}>
                    <CircularProgress color="primary" size={30} />
                </div>
            )}
        </button>
    )
}

export default Button